// Import dependencies
import React from 'react'

// Import components
import { TitleText } from '../styled/UILibrary'

// Import styles
import './isocontainer.css'


const ISOContainer = ({ noMargin, isoTitle, title1, title2, image, link }) => (
    <a className='isocontainer__row' href={ link } aria-label="ISO">
        <div>
            <img alt='iso-badge' src={ image } className='isocontainer__iso-icon' style={noMargin ? { marginLeft: 0 } : null} />
        </div>
        <div className='isocontainer__text-container'>
            <TitleText minSize={16} maxSize={16} nolineheight white shadow>
                { isoTitle }
            </TitleText>
            <div>
                <TitleText minSize={16} maxSize={16} nolineheight white shadow>
                    {title1}
                </TitleText>
                <TitleText minSize={16} maxSize={16} nolineheight white shadow>
                    {title2}
                </TitleText>
            </div>
        </div>
    </a>
)

export default ISOContainer

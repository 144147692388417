// Import dependencies
import React from "react"

// Import components
import { SubTitleText, TitleText, ParagraphText } from "../styled/UILibrary"

// Import styles
import "./textsection.css"

const TextSection = ({
  smallTitle,
  title,
  paragraph,
  contactAction,
  green,
  name,
}) => (
  <div className="textsection__container" name={name}>
    <SubTitleText minSize={14} maxSize={16} green={green}>
      {contactAction ? "CONTACT INFORMATION" : smallTitle}
    </SubTitleText>
    <TitleText
      minSize={22}
      maxSize={40}
      style={green ? null : { width: "70%" }}
    >
      {contactAction
        ? "Get in touch with a professional at Spiroplastic. Contact us today!"
        : title}
    </TitleText>

    {contactAction ? (
      <div>
        <ParagraphText minSize={18} maxSize={20} style={{ marginTop: 40 }}>
          The head office of Spiroplastic S.A. is located at:
        </ParagraphText>
        <a
          href="https://www.google.com/maps/place/4+Omarat+Aliamni,+Omar+Al+Khayam,+Zamalek,+Cairo+Governorate,+Egypt/@30.0577346,31.2206062,17z/data=!3m1!4b1!4m5!3m4!1s0x145840e753f81e9b:0x6cda423b8799e658!8m2!3d30.0577346!4d31.2227949"
          className="textsection__link"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Google Maps"
        >
          <ParagraphText minSize={18} maxSize={20} link>
            4 Omarat Aliamni St., Zamalek, Cairo
          </ParagraphText>
        </a>
      </div>
    ) : (
      <ParagraphText minSize={18} maxSize={20} style={{ marginTop: 40 }}>
        {paragraph}
      </ParagraphText>
    )}
  </div>
)

export default TextSection

// Import dependencies
import React from 'react'
import { Link } from 'gatsby'

// Import components
import ISOContainer from '../general/isoContainer'
import { PageContainer, Margins, TitleText } from '../styled/UILibrary'

// Import styles
import './homeheader.css'
import '../header/header.css'

// Import icons
import Logo from '../../assets/icons/home-page-logo.svg'

// Import images
import iso9001 from '../../assets/images/iso-9001.png'
import iso14001 from '../../assets/images/iso-14001.png'
import iso18001 from '../../assets/images/iso-18001.png'


export default class HomeHeader extends React.Component {
    render() {
        return (
            <div className='homeheader__container'>
                <PageContainer margin={'40px'}>
                    <Margins>
                        <div className='homeheader__content-container'>
                            {
                                this.props.header ?
                                    <div className='homeheader__navbar-container'>
                                        <Logo className='homeheader__logo' />
                                        <div className='homeheader__links-container'>
                                            <Link to='/' className={'header__link header__link-white'} style={{ marginLeft: 0 }}>HOME</Link>
                                            <Link to='/recycled' className={'header__link header__link-white'}>RECYCLED</Link>
                                            <Link to='/history' className={'header__link header__link-white'}>HISTORY</Link>
                                            <Link to='/about' className={'header__link header__link-white'}>ABOUT</Link>
                                            <Link to='/products' className={'header__link header__link-white'}>PRODUCTS</Link>
                                            <Link to='/contact' className={'header__link header__link-white'}>CONTACT</Link>
                                        </div>
                                    </div>
                                    :
                                    <div />
                            }

                            <div>
                                <TitleText minSize={40} maxSize={65} white shadow nolineheight>
                                    {this.props.title}
                                </TitleText>
                            </div>

                            <div className='homeheader__iso-container'>
                                <ISOContainer
                                    noMargin
                                    isoTitle='ISO 9001:2015'
                                    title1='Quality'
                                    title2='Management'
                                    image={iso9001}
                                    link='https://www.iso.org/iso-9001-quality-management.html'
                                />
                                <ISOContainer
                                    noMargin
                                    isoTitle='ISO 14001:2015'
                                    title1='Environmental'
                                    title2='Management'
                                    image={iso14001}
                                    link='https://www.iso.org/iso-14001-environmental-management.html'
                                />
                                <ISOContainer
                                    noMargin
                                    isoTitle='ISO 18001:2007'
                                    title1='OHSAS'
                                    title2='Management'
                                    image={iso18001}
                                    link='https://en.wikipedia.org/wiki/OHSAS_18001'
                                />
                            </div>
                        </div>
                    </Margins>
                </PageContainer>
            </div>
        );
    }
}

// Import dependencies
import React from "react"

// Import components
import TextSection from "./textSection"
import OutlineButton from "./outlineButton"

// Import styles
import "./contactaction.css"

const ContactCallToAction = () => (
  <div className="contactaction__container">
    <TextSection contactAction={true} />

    <div className="contactaction__row-container">
      {/* <div>
                <TitleText minSize={22} maxSize={40}>
                    (+202) 27351005
                </TitleText>
                <ParagraphText minSize={12} maxSize={18}>
                    Spiroplastic S.A. Cairo office telephone
                </ParagraphText>
            </div> */}

      <OutlineButton title="GO TO CONTACTS PAGE" destination="/contact" />
    </div>
  </div>
)

export default ContactCallToAction

// Import dependencies
import React from 'react'
import { Link } from 'gatsby'

// Import components
import { ParagraphText } from '../styled/UILibrary'

// Import styles
import './outlinebutton.css'

const OutlineButton = ({ title, destination, margin }) => (
    <Link
        to={destination}
        className='outlinebutton__container'
        style={margin ? margin : null}
    >
        <ParagraphText minSize={14} maxSize={16} link>
            {title}
        </ParagraphText>
    </Link>
)

export default OutlineButton
